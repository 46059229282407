export const lightTheme= {
  name:'lightTheme',
  text:'#2b2b2b',
  background:'#fffef2'
}

export const darkTheme = {
  name:'darkTheme',
  text:'white',
  background:'#1b2029'
}
